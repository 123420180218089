import { FormikTouched, FormikErrors } from "formik";

import { State as Model } from "./slice";
import Place from '../models/Place';

class Form {
  constructor(
    private touched: FormikTouched<Model>,
    private errors: FormikErrors<Model>
  ) { };

  isCollectValid(): boolean {
    return !this.touched.collect || !Boolean(this.errors.collect);
  }

  collectError(): string {
    if (this.touched.collect) {
      if (typeof this.errors.collect === 'string') {
        return this.errors.collect;
      } else if (typeof this.errors.collect === 'object') {
        const { place } = this.errors.collect as { place: FormikErrors<Place> };
        return place.street ?? place.streetNumber ?? place.city ?? place.country ?? "";
      }
    }
    return "";
  }

  isCollectPartnerValid(): boolean {
    return !this.touched.collectPartner || !Boolean(this.errors.collectPartner);
  }

  collectPartnerError(): string {
    return this.touched.collectPartner ? this.errors.collectPartner as string : "";
  }
  
  isDeliverValid(): boolean {
    return !this.touched.deliver || !Boolean(this.errors.deliver);
  }

  deliverError(): string {
    if (this.touched.deliver) {
      if (typeof this.errors.deliver === 'string') {
        return this.errors.deliver;
      } else if (typeof this.errors.deliver === 'object') {
        const { place } = this.errors.deliver as { place: FormikErrors<Place> };
        return place.street ?? place.streetNumber ?? place.city ?? place.country ?? "";
      }
    }
    return "";
  }

  isDeliveryPartnerValid(): boolean {
    return !this.touched.deliveryPartner || !Boolean(this.errors.deliveryPartner);
  }

  deliveryPartnerError(): string {
    return this.touched.deliveryPartner ? this.errors.deliveryPartner as string : "";
  }

  isPickupValid(): boolean {
    return !this.touched.pickup || !Boolean(this.errors.pickup);
  }

  pickupError(): string {
    if (this.touched.pickup) {
      if (typeof this.errors.pickup === 'string') {
        return this.errors.pickup;
      } else if (typeof this.errors.pickup === 'object') {
        const { place } = this.errors.pickup as { place: FormikErrors<Place> };
        return place.street ?? place.streetNumber ?? place.city ?? place.country ?? "";
      }
    }
    return "";
  }

  isBackValid(): boolean {
    return !this.touched.back || !Boolean(this.errors.back);
  }

  backError(): string {
    if (this.touched.back) {
      if (typeof this.errors.back === 'string') {
        return this.errors.back;
      } else if (typeof this.errors.back === 'object') {
        const { place } = this.errors.back as { place: FormikErrors<Place> };
        return place.street ?? place.streetNumber ?? place.city ?? place.country ?? "";
      }
    }
    return "";
  }
}

export default Form;