import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Alert, useTheme } from "@mui/material";

import Container from "../shared/Container";
import Header from "../shared/Header";
import StepperActions from "../shared/StepperActions";
import FormCheckbox from "../shared/FormCheckbox";
import { Title, Body } from "../typography/Texts";
import PlanCard from "./PlanCard";

import { State } from "../store";
import Cancellation from "../models/Cancellation";
import { setNoPlan, setPlan } from "./slice";

interface Props {
  plans: Array<Cancellation>
  onNext: () => void
  onBack: () => void
}

const CancellationPlans: React.FC<Props> = ({ plans, onNext, onBack }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState<boolean | null>(null);

  const noPlan: boolean = useSelector((state: State) => state.step4.noPlan);
  const selectedPlan: Cancellation | null = useSelector((state: State) => state.step4.plan);

  const onNoPlanChange = (value: boolean) => {
    dispatch(setNoPlan(value));
    setIsValid(null);
  }

  const onPlanSelected = (plan: Cancellation | null) => {
    dispatch(setPlan(plan));
    setIsValid(null);
  }

  const handleNext = () => {
    if (noPlan || selectedPlan !== null) {
      onNext();
    } else {
      setIsValid(false);
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <Header title={t('cancellationStep')} />
        <Grid container spacing={3} justifyContent="start">
          {plans.map(plan => {
            return (
              <Grid item xs={12} lg={4} key={plan.id}>
                <PlanCard
                  isSelected={selectedPlan === plan}
                  plan={plan}
                  onSelect={onPlanSelected} />
              </Grid>
            )
          })}
        </Grid>
        <Grid 
          container 
          columnSpacing={0} 
          marginY={5}
          padding={2}
          sx={{backgroundColor: theme.palette.primary.light}}>
          <Grid item>
            <FormCheckbox 
              checked={noPlan} 
              onChange={(e) => onNoPlanChange(e.target.checked)} 
            />
          </Grid>
          <Grid item xs={true}>
            <Body>{t('insurance.decline.noThanks')}</Body>
            <Body>{t('insurance.decline.understandResponsibility')}</Body>
          </Grid>
        </Grid>
        { isValid === false && (
          <Alert 
            variant="outlined" 
            severity="error" 
            sx={{marginBottom: 5}}>
            {t('insurance.alert.selectOneOption')}
          </Alert>
        )}
        <Title>{t('insurance.confirmation.title')}</Title>
        <ul>
          <li>{t('insurance.confirmation.readAndAccept')}</li>
          <li>{t('insurance.confirmation.verifiedNeeds')}</li>
          <li>{t('insurance.confirmation.receiveDigitally')}</li>
        </ul>
      </Container>
      <StepperActions onNext={handleNext} onBack={onBack} />
    </Box>
  )
}

export default CancellationPlans;