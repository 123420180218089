import React from "react";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText, Typography } from '@mui/material';

import Money from "../../../helpers/Money";

interface Props {
  name: string | React.ReactNode
  unitPrice?: number
  quantity?: number
  price?: number
}

const LuggageRow: React.FC<Props> = ({ name, unitPrice, quantity = 0, price }) => {
  const { t } = useTranslation();
  return (
    <ListItem disablePadding sx={{alignItems: "flex-end"}}>
      <ListItemText
        primary={quantity > 0 ? `${name} - ${quantity}x` : name}
        secondary={unitPrice ? `${Money.fromCents(unitPrice)} € x ${quantity}` : null} />
        { price && (
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {Money.fromCents(price)} €
          </Typography>
        )}
    </ListItem>
  )
}

export default LuggageRow;