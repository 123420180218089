import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, FormControlLabel, Checkbox, Divider } from "@mui/material";
import { useFormik } from "formik";

import Container from "../shared/Container";
import Header from "../shared/Header";
import RequiredLabel from "../shared/RequiredLabel";
import StepperActions from "../shared/StepperActions";
import AddressInput from "../shared/AddressInput";
import Partners from "./Partners";

import { useAppDispatch, State } from "../store";
import Address from "../models/Address";
import TripType from "../models/TripType";
import Country from "../models/Country";
import validationSchema from "./validationSchema";
import {
  setCollect, setCollectPartner, setCollectPartners,
  setDeliver, setDeliveryPartner, setDeliveryPartners,
  isDeliverSameAsPickup,
  setPickup, setPickupPartner, setPickupPartners,
  isCollectSameAsBack,
  setBack,
  State as Model
} from "./slice";

import Form from "./Form";

import fetchPartners from "../api/fetchPartners";

interface Props {
  countries: Array<Country>
  onNext: () => void
  onBack: () => void
}

const Trip: React.FC<Props> = ({ countries, onNext, onBack }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  // TripType
  const tripType: TripType = useSelector((state: State) => state.step1.tripType);

  const model: Model = useSelector((state: State) => state.step2);

  const { values, touched, errors, setFieldValue, validateField, handleSubmit } = useFormik({
    initialValues: {
      ...model,
      tripType
    },
    validationSchema,
    onSubmit: onNext,
  });

  const form: Form = new Form(touched, errors);

  useEffect(() => {
    dispatch(setCollect(values.collect));
    dispatch(setCollectPartner(values.collectPartner));
    dispatch(setCollectPartners(values.collectPartners));
    dispatch(setDeliver(values.deliver));
    dispatch(setDeliveryPartners(values.deliveryPartners));
    dispatch(setDeliveryPartner(values.deliveryPartner));
    dispatch(isDeliverSameAsPickup(values.isDeliverSameAsPickup));
    dispatch(setPickup(values.pickup));
    dispatch(setPickupPartners(values.pickupPartners));
    dispatch(setPickupPartner(values.pickupPartner));
    dispatch(isCollectSameAsBack(values.isCollectSameAsBack));
    dispatch(setBack(values.back));
    validateField('collect');
    validateField('deliver');
    validateField('pickup');
    validateField('back');
  }, [values]);

  // Collect
  const isCollectAddressSetup = tripType === TripType.RETURN && (values.collectPartner || values.collect);

  const isCollectSameAsBackLabel = (): string => {
    let address = values.collectPartner?.address ?? values.collect?.fullText() ?? '';
    return t('luggageReturnQuestion', { address: `"${address}"` });
  }

  const onCollectSameAsBackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('isCollectSameAsBack', e.target.checked);
  }

  const onCollectChange = (value: Address | null) => {
    setFieldValue('collect', value);
    setFieldValue('isCollectSameAsBack', true);
    setFieldValue('back', null);

    if (value && value.place) {
      fetchPartners(value).then(partners => {
        setFieldValue('collectPartners', partners);
        setFieldValue('collectPartner', partners.length === 1 ? partners[0] : null);
      });
    } else {
      setFieldValue('collectPartners', []);
      setFieldValue('collectPartner', null);
    }
  }

  // Deliver
  const isDeliveryAddressSetup = tripType === TripType.RETURN && (values.deliveryPartner || values.deliver);

  const isDeliverSameAsPickupLabel = (): string => {
    let address = values.deliveryPartner?.address ?? values.deliver?.fullText() ?? '';
    return t('pickupAddressConfirmation', { address: `"${address}"` });
  }

  const onDeliverChange = (value: Address | null) => {
    setFieldValue('deliver', value);
    setFieldValue('isDeliverSameAsPickup', true);
    setFieldValue('pickup', null);

    if (value && value.place) {
      fetchPartners(value).then(partners => {
        setFieldValue('deliveryPartners', partners);
        setFieldValue('deliveryPartner', partners.length === 1 ? partners[0] : null);
      });
    } else {
      setFieldValue('deliveryPartners', []);
      setFieldValue('deliveryPartner', null);
    }
  }

  // Pickup
  const onDeliverSameAsPickupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('isDeliverSameAsPickup', e.target.checked);
  }

  const onPickupChange = (value: Address | null) => {
    setFieldValue('pickup', value);

    if (value && value.place) {
      fetchPartners(value).then(partners => {
        setFieldValue('pickupPartners', partners);
        setFieldValue('pickupPartner', partners.length === 1 ? partners[0] : null);
      });
    } else {
      setFieldValue('pickupPartners', []);
      setFieldValue('pickupPartner', null);
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <Header title={t('tripDetails')} />
        <Box sx={{ marginBottom: 2 }}>
          <RequiredLabel title={t('collectFrom')} helpText={t('collectFromInformation')} />
          <AddressInput 
            language={i18n.language}
            countries={countries}
            isValid={form.isCollectValid()}
            helperText={t(form.collectError())}
            placeholder={t('collectFromPlaceholder')}
            value={values.collect} 
            onChange={onCollectChange} />
          {values.collectPartners.length > 0 && (
            <Partners
              isValid={form.isCollectPartnerValid()}
              partners={values.collectPartners}
              selected={values.collectPartner}
              helperText={t(form.collectPartnerError())}
              onChecked={(partner) => setFieldValue('collectPartner', partner)} />
          )}
          {isCollectAddressSetup && (
            <FormControlLabel 
              control={<Checkbox checked={values.isCollectSameAsBack} onChange={onCollectSameAsBackChange} />} 
              label={isCollectSameAsBackLabel()} />
          )}
        </Box>

        <Box sx={{ marginBottom: 2, display: isCollectAddressSetup && !values.isCollectSameAsBack ? 'block' : 'none' }}>
          <RequiredLabel title={t('returnTo')} helpText={t('returnToInformation')} />
          <AddressInput
            language={i18n.language} 
            countries={countries}
            isValid={form.isBackValid()}
            helperText={t(form.backError())}
            placeholder={t('returnToPlaceholder')}
            value={values.back} 
            onChange={(value) => setFieldValue('back', value)} />
          <Divider sx={{marginY: 2}} />
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <RequiredLabel title={t('deliverTo')} helpText={t('deliverToInformation')} />
          <AddressInput
            language={i18n.language}
            countries={countries}
            isValid={form.isDeliverValid()}
            helperText={t(form.deliverError())}
            placeholder={t('deliverToPlaceholder')}
            value={values.deliver} 
            onChange={onDeliverChange} />
          {values.deliveryPartners.length > 0 && (
            <Partners
              isValid={form.isDeliveryPartnerValid()}
              partners={values.deliveryPartners}
              selected={values.deliveryPartner}
              helperText={t(form.deliveryPartnerError())}
              onChecked={(partner) => setFieldValue('deliveryPartner', partner)} />
          )}
          {isDeliveryAddressSetup && (
            <FormControlLabel 
              control={<Checkbox checked={values.isDeliverSameAsPickup} onChange={onDeliverSameAsPickupChange} />} 
              label={isDeliverSameAsPickupLabel()} />
          )}
        </Box>
        <Box sx={{ marginBottom: 2, display: isDeliveryAddressSetup && !values.isDeliverSameAsPickup ? 'block' : 'none' }}>
          <RequiredLabel title={t('pickupAt')} helpText={t('pickupAtInformation')} />
          <AddressInput 
            language={i18n.language}
            countries={countries}
            isValid={form.isPickupValid()}
            helperText={t(form.pickupError())}
            placeholder={t('pickupAtPlaceholder')}
            value={values.pickup} 
            onChange={onPickupChange} />
          {values.pickupPartners.length > 0 && (
            <Partners
              partners={values.pickupPartners}
              selected={values.pickupPartner}
              onChecked={(partner) => setFieldValue('pickupPartner', partner)} />
          )}
        </Box>
      </Container>
      <StepperActions onNext={handleSubmit} onBack={onBack} />
    </Box>
  )
}

export default Trip;