import moment, { Moment } from 'moment';

export interface RestrictedDate {
  date: any
}

export class DateValidator {
  departureDate: Moment;
  returnDate: Moment;
  restrictedDates: Moment[];

  constructor(departureDate: string | null, returnDate: string | null, restrictedDates: RestrictedDate[]) {
    this.departureDate = moment(departureDate);
    this.returnDate = moment(returnDate);
    this.restrictedDates = restrictedDates.map(restrictedDate => moment(restrictedDate.date));
  }

  private isARestrictedDate(date: Moment): boolean {
    return this.restrictedDates.some(restrictedDate => restrictedDate.isSame(date));
  }

  isAfterToday(date: Moment): boolean {
    return date.isAfter(moment()) && !this.isARestrictedDate(date);
  }

  isMinimunDate(date: Moment): boolean {
    return date.isBefore(this.returnDate) && this.isAfterToday(date);
  }

  isMaximunDate(date: Moment): boolean {
    return date.isAfter(this.departureDate) && this.isAfterToday(date);
  }

  isEligibleDepartureDate(date: Moment): boolean {
    return this.returnDate.isValid() ? this.isMinimunDate(date) : this.isAfterToday(date);
  }

  isEligibleReturnDate(date: Moment): boolean {
    return this.departureDate.isValid() ? this.isMaximunDate(date) : this.isAfterToday(date);
  }

  referenceReturnDate(): Moment | null {
    return this.departureDate.isValid() ? this.departureDate.add(1, 'day') : moment();
  }
}
