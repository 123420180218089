import moment, { type MomentInput } from 'moment';

class DateHelper {
  private static readonly DATE_FORMAT = "YYYY-MM-DD";

  static toString(date: MomentInput, format: string = this.DATE_FORMAT): string {
    return moment(date).format(format);
  }
}

export default DateHelper;
