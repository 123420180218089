import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Alert,
  FormControl, FormControlLabel,
  InputLabel,
  RadioGroup, Radio,
  Button, IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useFormikContext, Formik } from "formik";

import Container from "../shared/Container";
import Header from "../shared/Header";
import RequiredLabel from "../shared/RequiredLabel";
import StepperActions from "../shared/StepperActions";
import Datepicker from "./Datepicker";
import LugaggeTypeSelect from "./LuggageTypeSelect";
import QuantitySelect from "./QuantitySelect";
import { Title } from "../typography/Texts";

import { State } from "../store";
import Luggage, { Type as LugaggeType } from "../models/Luggage";
import TripType from "../models/TripType";
import validationSchema from "./validationSchema";
import { DateValidator, RestrictedDate } from "./validations";
import { setTripType, setDepartureDate, setReturnDate, setLugagges, State as Model } from "./slice";

import Form from "./Form";

interface Props {
  typesOfLuggage: Array<LugaggeType>
  restrictedDates: Array<RestrictedDate>
  onNext: () => void,
}

const LuggageForm: React.FC<Props> = ({ typesOfLuggage, restrictedDates }) => {
  const { i18n, t } = useTranslation();

  // Lugagges
  const onAddLuggage = () => {
    const lugagges = [...values.lugagges, { typeId: "", quantity: "" }];
    setFieldValue('lugagges', lugagges);
  }
  const onChangeLuggage = (index: number, data: any) => {
    const lugagges = values.lugagges.map((item, i) => index === i ? { ...item, ...data } : item);
    setFieldValue('lugagges', lugagges);
  }
  const onDeleteLuggage = (index: number) => {
    setFieldValue('lugagges', values.lugagges.filter((_, i) => i !== index));
  }

  const filteredTypesOfLuggage = (luggage: Luggage): LugaggeType[] => typesOfLuggage.filter(type =>
    type.id === luggage.typeId || !values.lugagges.some((l: Luggage) => l.typeId === type.id)
  );

  const { values, touched, errors, setFieldValue, handleChange, handleSubmit } = useFormikContext<Model>();

  const dateValidator = new DateValidator(values.departureDate, values.returnDate, restrictedDates);
  const form = new Form(touched, errors);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <Header title={t('tripType')} />
        <FormControl>
          <RadioGroup row name="tripType" value={values.tripType} onChange={handleChange}>
            <FormControlLabel value={TripType.ONE_WAY} control={<Radio />} label={t('oneWay')} />
            <FormControlLabel value={TripType.RETURN} control={<Radio />} label={t('twoWay')} />
          </RadioGroup>
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RequiredLabel title={t('departure')} helpText={t('departureInformation')} />
            <Datepicker
              locale={i18n.language}
              name="departureDate"
              shouldDisableDate={(date) => !dateValidator.isEligibleDepartureDate(date)}
            />
          </Grid>
          {values.tripType === TripType.RETURN && (
            <Grid item xs={6}>
              <RequiredLabel title={t('returnDate')} helpText={t('returnDateInformation')} />
              <Datepicker
                locale={i18n.language}
                name="returnDate"
                referenceDate={dateValidator.referenceReturnDate()}
                shouldDisableDate={(date) => !dateValidator.isEligibleReturnDate(date)}
              />
            </Grid>
          )}
        </Grid>
        <Title className="pdt-20 pdb-10">{t('luggageDetail')}</Title>
        {!form.isLugaggesValid() && (
          <Alert severity="error" color="error" className="mb-15">
            {t(form.lugaggesError())}
          </Alert>
        )}
        <Grid container spacing={2}>
          {values.lugagges.map((value, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <InputLabel>
                  {t('luggage')}
                </InputLabel>
                <LugaggeTypeSelect
                  locale={i18n.language}
                  empty={t('select')}
                  types={filteredTypesOfLuggage(value)}
                  value={value.typeId}
                  isValid={form.isLugaggeTypeValid(index)}
                  helperText={t(form.lugaggeTypeError(index))}
                  onChange={(typeId) => onChangeLuggage(index, { typeId })} />
              </Grid>
              <Grid item xs={5}>
                <InputLabel>
                  {t('quantity')}
                </InputLabel>
                <QuantitySelect
                  empty={t('select')}
                  quantity={50}
                  value={value.quantity}
                  isValid={form.isQuantityValid(index)}
                  helperText={t(form.quantityError(index))}
                  onChange={(quantity) => onChangeLuggage(index, { quantity })}
                />
              </Grid>
              <Grid item xs={1} alignSelf="flex-end">
                {index !== 0 && (
                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => onDeleteLuggage(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Box className="pdt-10" sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
          <Button variant="text" startIcon={<AddIcon />} onClick={onAddLuggage}>
            {t('moreLuggage')}
          </Button>
        </Box>
      </Container>
      <StepperActions onNext={handleSubmit} />
    </Box>
  )
}

const Luggages: React.FC<Props> = props => {
  const initialValues: Model = useSelector((state: State) => state.step1);
  const schema = validationSchema(props.restrictedDates.map(d => moment(d.date)));
  const dispatch = useDispatch();
  const onSubmit = (values: Model) => {
    dispatch(setTripType(TripType.valueOf(values.tripType)));
    dispatch(setDepartureDate(moment(values.departureDate).toISOString()));
    dispatch(setReturnDate(moment(values.returnDate).toISOString()));
    dispatch(setLugagges(values.lugagges));
    props.onNext();
  };
  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      <LuggageForm {...props} />
    </Formik>
  )
}

export default Luggages;
