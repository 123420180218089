import React from "react";
import {
  FormGroup, FormControl,
  FormControlLabel, FormHelperText,
  Checkbox, CheckboxProps
} from "@mui/material";

interface Props extends CheckboxProps {
  label?: React.ReactNode
  isValid?: boolean
  helperText?: string | null
}

const FormCheckbox: React.FC<Props> = ({ label, isValid = true, helperText, ...props }) => {
  return (
    <FormControl error={!isValid}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...props} />}
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormGroup>
    </FormControl>
  )
}

export default FormCheckbox;