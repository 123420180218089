import { createSlice } from "@reduxjs/toolkit";

import PaymentType from "../models/PaymentType";

export interface State {
  paymentType: PaymentType
  transactionId: string,
  otherInformation: string
}

const initialState: State = {
  paymentType: PaymentType.IDEAL,
  transactionId: "",
  otherInformation: ""
}

const summarySlice = createSlice({
  name: "summarySlice",
  initialState: initialState,
  reducers: {
    setPaymentType(state, action) {
      state.paymentType = PaymentType.valueOf(action.payload) ?? state.paymentType;
      state.transactionId = "";
    },
    setTransactionId(state, action) {
      state.transactionId = action.payload;
    },
    setOtherInformation(state, action) {
      state.otherInformation = action.payload;
    }
  }
});

export const { setPaymentType, setTransactionId, setOtherInformation } = summarySlice.actions;
export default summarySlice.reducer;