import * as yup from "yup";

import TripType from "../models/TripType";
import Address from "../models/Address";
import Partner from "../models/Partner";
import Place from '../models/Place';

const placeSchema = yup.object<Place>({
  street: yup.string().required("streetNotPresent").trim(),
  streetNumber: yup.string().required("streetNotPresent").trim(),
  city: yup.string().required("cityNotPresent").trim(),
  country: yup.string().required("countryNotPresent").trim(),
}).required("required");

const validationSchema = yup.object({
  tripType: yup
    .string()
    .oneOf([TripType.ONE_WAY, TripType.RETURN]),
  collect: yup
    .object()
    .required("required")
    .when('collectPartners', (values: Array<Array<Partner>>, schema: yup.ObjectSchema<{}>) => (
      values[0].length > 0 ? schema : yup.object<Address>({ place: placeSchema }).required("required")
    )),
  collectPartners: yup
    .array<Partner>(),
  collectPartner: yup
    .object<Partner>()
    .when('collectPartners', (values: Array<Array<Partner>>, schema: yup.ObjectSchema<{}>) => (
      values[0].length > 0 ? schema.required("requiredPartner") : schema.notRequired()
    )),
  deliver: yup
    .object()
    .required("required")
    .when('deliveryPartners', (values: Array<Array<Partner>>, schema: yup.ObjectSchema<{}>) => (
      values[0].length > 0 ? schema : yup.object<Address>({ place: placeSchema }).required("required")
    )),
  deliveryPartners: yup
    .array<Partner>(),
  deliveryPartner: yup
    .object<Partner>()
    .when('deliveryPartners', (values: Array<Array<Partner>>, schema: yup.ObjectSchema<{}>) => (
      values[0].length > 0 ? schema.required("requiredPartner") : schema.notRequired()
    )),
  isDeliverSameAsPickup: yup
    .boolean()
    .when('tripType', (values: Array<TripType>, schema: yup.BooleanSchema) => (
      values[0] === TripType.ONE_WAY ? schema.notRequired() : schema.required("required")
    )),
  pickup: yup
    .object()
    .when(['isDeliverSameAsPickup', 'pickupPartners'], (values: any[], schema: yup.ObjectSchema<{}>) => {
      const isDeliverSameAsPickup: boolean = values[0];
      const pickupPartners: Array<Partner> = values[1];
      if (isDeliverSameAsPickup) {
        return schema.notRequired()
      } else if (pickupPartners.length > 0) {
        return schema.required("required")
      } else {
        return yup.object<Address>({ place: placeSchema }).required("required")
      }
    }),
  pickupPartners: yup
    .array<Partner>(),
  pickupPartner: yup
    .object<Partner>()
    .when(['isDeliverSameAsPickup', 'pickupPartners'], (values: any[], schema: yup.ObjectSchema<{}>) => {
      const isDeliverSameAsPickup: boolean = values[0];
      const pickupPartners: Array<Partner> = values[1];
      return !isDeliverSameAsPickup && pickupPartners.length > 0 ? schema.required("requiredPartner") : schema.notRequired()
    }),
  isCollectSameAsBack: yup
    .boolean()
    .when('tripType', (values: Array<TripType>, schema: yup.BooleanSchema) => (
      values[0] === TripType.ONE_WAY ? schema.notRequired() : schema.required("required")
    )),
  back: yup
    .object<Address>({place: placeSchema})
    .when('isCollectSameAsBack', (values: Array<boolean>, schema: yup.ObjectSchema<{}>) => (
      values[0] === true ? schema.notRequired() : schema.required("required")
    )),
});

export default validationSchema;