import axios from "./axios";

import DateHelper from '../../../helpers/DateHelper';

import { State as ContactState } from "../contact/slice";
import { State as LuggagesState } from "../luggages/slice";
import { State as TripState } from "../trip/slice";
import { State as CancellationState } from "../cancellation/slice";
import { State as SummaryState } from "../summary/slice";

const DATE_FORMAT = 'DD/MM/YYYY';

interface Response {
  redirect_url: string
}

const booking = async (
  authenticityToken: string, 
  contactState: ContactState, 
  tripState: TripState, 
  lugaggesState: LuggagesState, 
  cancellationState: CancellationState,
  summaryState: SummaryState
) : Promise<Response> => {
  const contact_information = {
    'title': contactState.title,
    'first_name': contactState.firstName,
    'last_name': contactState.lastName,
    'phone': contactState.phone,
    'email': contactState.email,
  };

  const shipping_address = {
    'departure_date': DateHelper.toString(lugaggesState.departureDate, DATE_FORMAT),
    'return_date': lugaggesState.returnDate ? DateHelper.toString(lugaggesState.returnDate, DATE_FORMAT) : '',
    'name': tripState.collect?.fullText() ?? '',
    'street': tripState.collect?.place?.street ?? '',
    'address_number': tripState.collect?.place?.streetNumber ?? '',
    'postal_code': tripState.collect?.place?.postalCode ?? '',
    'city': tripState.collect?.place?.city ?? '',
    'country': tripState.collect?.place?.country,
    'is_the_same_return_address': tripState.isCollectSameAsBack,
    'partner_id': tripState.collectPartner?.id
  };

  const delivery_address = {
    'accommodatie': tripState.deliver?.fullText() ?? '',
    'street': tripState.deliver?.place?.street ?? '',
    'address_number': tripState.deliver?.place?.streetNumber ?? '',
    'postal_code': tripState.deliver?.place?.postalCode ?? '',
    'city': tripState.deliver?.place?.city ?? '',
    'country': tripState.deliver?.place?.country ?? '',
    'phone': '',
    'email': '',
    'other_information': summaryState.otherInformation,
    'terms_and_conditions': true,
    'is_the_same_pickup_address': tripState.isDeliverSameAsPickup,
    'partner_id': tripState.deliveryPartner?.id
  };

  const pickupAddressData = {
    'accommodatie': tripState.pickup?.fullText(),
    'street': tripState.pickup?.place?.street ?? '',
    'address_number': tripState.pickup?.place?.streetNumber ?? '',
    'postal_code': tripState.pickup?.place?.postalCode ?? '',
    'city': tripState.pickup?.place?.city ?? '',
    'country': tripState.pickup?.place?.country ?? '',
    'phone': '',
    'partner_id': tripState.pickupPartner?.id
  };

  const pickup_address = tripState.isDeliverSameAsPickup ? delivery_address : pickupAddressData;

  const returnAddressData = {
    'street': `${tripState.back?.fullText()} ${tripState.back?.place?.street ?? ''}`,
    'address_number': tripState.back?.place?.streetNumber ?? '',
    'postal_code': tripState.back?.place?.postalCode ?? '',
    'city': tripState.back?.place?.city ?? '',
    'country': tripState.back?.place?.country ?? '',
  };

  const return_address = tripState.isCollectSameAsBack ? shipping_address : returnAddressData;

  const luggages = lugaggesState.lugagges.map(luggage => ({ luggage_id: luggage.typeId, quantity: luggage.quantity }));

  const data = {
    authenticity_token: authenticityToken,
    booking: {
      contact_information,
      shipping_address,
      delivery_address,
      pickup_address,
      return_address,
      travel_type: lugaggesState.tripType,
      luggages,
      transactionId: summaryState.transactionId,
      paymentType: summaryState.paymentType,
      cancellation_id: cancellationState.plan?.id,
      extra_luggage_id: null
    }
  };

  console.log(data);

  try {
    const response = await axios.post<Response>('/bookings', data);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e
  }
}

export default booking;