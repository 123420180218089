import { createSlice } from "@reduxjs/toolkit";

import Title from "../models/Title";

export interface State {
  title: Title | string
  firstName: string
  lastName: string
  email: string
  phone: string
}

const initialState: State = {
  title: Title.MR,
  firstName: '',
  lastName: '',
  email: '',
  phone: ''
}

const contactSlice = createSlice({
  name: "contactSlice",
  initialState: initialState,
  reducers: {
    setTitle(state, action) {
      state.title = Title.valueOf(action.payload) ?? state.title;
    },
    setFirstName(state, action) {
      state.firstName = action.payload;
    },
    setLastName(state, action) {
      state.lastName = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPhone(state, action) {
      state.phone = action.payload;
    }
  }
});

export const { setTitle, setFirstName, setLastName, setEmail, setPhone } = contactSlice.actions
export default contactSlice.reducer