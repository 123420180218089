import { createSlice } from "@reduxjs/toolkit";

import Luggage from "../models/Luggage";
import TripType from "../models/TripType";

export const BICYCLE_IDS = [1, 12]; // Array of IDs
export const BICYCLE_BAGS_ID = 2;

export interface State {
  tripType: TripType;
  departureDate: string | null;
  returnDate: string | null;
  lugagges: Array<Luggage>;
}

const initialState: State = {
  tripType: TripType.ONE_WAY,
  departureDate: null,
  returnDate: null,
  lugagges: [
    { typeId: BICYCLE_IDS[0], quantity: "" },
    { typeId: BICYCLE_BAGS_ID, quantity: "" }
  ]
};

const luggagesSlice = createSlice({
  name: "luggagesSlice",
  initialState: initialState,
  reducers: {
    setTripType(state, action) {
      state.tripType = TripType.valueOf(action.payload) ?? state.tripType;
      state.returnDate = null;
    },
    setDepartureDate(state, action) {
      state.departureDate = action.payload;
    },
    setReturnDate(state, action) {
      state.returnDate = action.payload;
    },
    setLugagges(state, action) {
      state.lugagges = action.payload;
    }
  }
});

export const { setTripType, setDepartureDate, setReturnDate, setLugagges } =
  luggagesSlice.actions;
export default luggagesSlice.reducer;

// Selector function to check if a typeId is in BICYCLE_IDS
export const isBicycleType = (typeId: number): boolean => {
  return BICYCLE_IDS.includes(typeId);
};
