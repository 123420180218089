import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";

import Container from "../shared/Container";
import Header from "../shared/Header";
import RequiredLabel from "../shared/RequiredLabel";
import StepperActions from "../shared/StepperActions";

import { State } from "../store";
import validationSchema from "./validationSchema";
import { setTitle, setFirstName, setLastName, setEmail, setPhone, State as Model } from "./slice";

import Form from "./Form";

interface Props {
  onNext: () => void
  onBack: () => void
}

const Contact: React.FC<Props> = ({ onNext, onBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: Model = useSelector((state: State) => state.step3);

  const { values, touched, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onNext,
  });

  const form = new Form(touched, errors);

  useEffect(() => {
    dispatch(setTitle(values.title));
    dispatch(setFirstName(values.firstName));
    dispatch(setLastName(values.lastName));
    dispatch(setEmail(values.email));
    dispatch(setPhone(values.phone));
  }, [values]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <Header title={t('personalInformation')} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <RequiredLabel title={t('firstNameStep2')} />
            <TextField
              fullWidth
              name="firstName"
              value={values.firstName}
              error={!form.isFirstNameValid()}
              helperText={t(form.firstNameError())}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <RequiredLabel title={t('lastNameStep2')} />
            <TextField
              fullWidth
              name="lastName"
              value={values.lastName}
              error={!form.isLastNameError()}
              helperText={t(form.lastNameError())}
              onChange={handleChange}
            />
          </Grid>
          
          <Grid item xs={5}>
            <RequiredLabel title={t('emailStep2')} />
            <TextField
              fullWidth
              name="email"
              type="email"
              placeholder="example@mail.com"
              value={values.email}
              error={!form.isEmailValid()}
              helperText={t(form.emailError())}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <RequiredLabel title={t('phoneNumberStep2')} />
            <TextField
              fullWidth
              name="phone"
              type="tel"
              value={values.phone}
              error={!form.isPhoneValid()}
              helperText={t(form.phoneError())}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Container>
      <StepperActions onNext={handleSubmit} onBack={onBack} />
    </Box>
  )
}

export default Contact;