import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Stack, Divider, List, Grid, InputLabel, TextField, Link } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import DateHelper from '../../../helpers/DateHelper';

import Container from "../shared/Container";
import StepperActions from "../shared/StepperActions";
import FormCheckbox from "../shared/FormCheckbox";
import Textarea from "../shared/Textarea";
import { Title, Subtitle, Body } from "../typography/Texts";
import InfoRow from "./InfoRow";
import AddressRow from "./AddressRow";
import LuggageRow from "./LuggageRow";
import PaymentTypeSelect from "./PaymentTypeSelect";

import { useAppDispatch, State } from "../store";
import User from "../models/User";
import TripType from "../models/TripType";
import PaymentType from "../models/PaymentType";
import Luggage, { Type as LugaggeType, ConfirmedLuggage } from "../models/Luggage";
import Cancellation, { AmountType } from "../models/Cancellation";
import { setPaymentType, setTransactionId, setOtherInformation } from "./slice";

import booking from "../api/booking";

const DATE_FORMAT = 'DD-MM-yyyy';
const VAT_PERCENTAJE = 0.21;

interface Props {
  authenticityToken: string
  user: User | null
  typesOfLuggage: Array<LugaggeType>
  onBack: () => void
}

const Summary: React.FC<Props> = ({ authenticityToken, user, typesOfLuggage, onBack }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const tripType: TripType = useSelector((state: State) => state.step1.tripType);
  const luggage = useSelector((state: State) => state.step1);
  const trip = useSelector((state: State) => state.step2);
  const contact = useSelector((state: State) => state.step3);
  const cancellation = useSelector((state: State) => state.step4);
  const summary = useSelector((state: State) => state.step5);
  const noPlan: boolean = cancellation.noPlan;
  const plan: Cancellation | null = cancellation.plan;

  const onTermsAndConditionsChange = (value: boolean) => {
    setTermsAndConditions(value);
    setError(null);
  }

  const confirmedLuggages: ConfirmedLuggage[] = luggage.lugagges.flatMap((l: Luggage) => {
    if (typeof l.typeId === 'number' && typeof l.quantity === 'number' && l.quantity > 0) {
      const type: LugaggeType = typesOfLuggage.find(t => t.id === l.typeId)!!;
      return [new ConfirmedLuggage(type, l)];
    } else {
      return [];
    }
  });

  const contactName = (): string => `${contact.firstName} ${contact.lastName}`;

  const shippingAddress = (): string => trip.collectPartner?.address ?? trip.collect?.fullText() ?? '';

  const deliveryAddress = (): string => trip.deliveryPartner?.address ?? trip.deliver?.fullText() ?? '';

  const pickupAddress = (): string => {
    if (trip.isDeliverSameAsPickup) {
      return deliveryAddress();
    } else if (trip.pickupPartner) {
      return trip.pickupPartner.address;
    } else {
      return trip.pickup?.fullText() ?? '';
    }
  }

  const returnAddress = (): string => {
    if (trip.isCollectSameAsBack) {
      return shippingAddress();
    } else {
      return trip.back?.fullText() ?? '';
    }
  }

  const luggageItem = (luggage: ConfirmedLuggage) => {
    const type: LugaggeType = luggage.type;
    const name: string = type.translations.find(t => t.locale === i18n.language)?.name ?? type.name;
    const quantity: number = luggage.quantity();
    const unitPrice: number = tripType === TripType.RETURN ? type.round_trip_price_cents : type.one_way_price_cents;
    const amount: number = quantity * unitPrice;
    return (
      <LuggageRow
        name={name}
        quantity={quantity}
        unitPrice={unitPrice}
        price={amount} />
    )
  }

  const planName = (plan: Cancellation) => plan.amount_type === AmountType.percentaje ? `${plan.title} (${plan.amount}%)` : plan.title;

  const bicyclesQuantity = confirmedLuggages.reduce((acc, luggage) => luggage.isBicycle() ? acc + luggage.quantity() : acc, 0);

  const totalLuggageCost: number = useMemo(() => {
    return confirmedLuggages.reduce((acc: number, luggage: ConfirmedLuggage) => {
      const unitPrice = tripType === TripType.RETURN ? luggage.type.round_trip_price_cents : luggage.type.one_way_price_cents;
      const amount = unitPrice * luggage.quantity();
      return acc + amount;
    }, 0);
  }, []);

  const planPrice = !noPlan && plan ? Cancellation.price(totalLuggageCost, bicyclesQuantity, plan) : 0;
  const total = totalLuggageCost + planPrice;
  const vat = total * VAT_PERCENTAJE;
  const subtotal = total - vat;

  const handleFinish = () => { 
    if (termsAndConditions) {
      const luggages = {...luggage, lugagges: confirmedLuggages.map((l: ConfirmedLuggage) => l.luggage) };
      setError(null);
      booking(authenticityToken, contact, trip, luggages, cancellation, summary)
        .then(response => { window.location.href = response.redirect_url; });
    } else {
      setError(t('required'));
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <Stack spacing={2}>
          <Stack
            direction="column"
            divider={<Divider flexItem />}
            spacing={2}
            sx={{ my: 2 }}
          >
            <div>
              <Title marginBottom={2}>{t('personalInformation')}</Title>
              <List dense disablePadding>
                <InfoRow text={contactName()} />
                <InfoRow leftIcon={EmailIcon} text={contact.email} />
                <InfoRow leftIcon={PhoneIcon} text={contact.phone} />
              </List>
            </div>
            <div>
              <Title marginBottom={2}>{t('tripInformationStep')}</Title>
              <List dense disablePadding>
                <AddressRow primary={t('departure')} secondary={DateHelper.toString(luggage.departureDate, DATE_FORMAT)} />
                {tripType === TripType.RETURN && <AddressRow primary={t('returnDate')} secondary={DateHelper.toString(luggage.returnDate, DATE_FORMAT)} />}
                <AddressRow primary={t('shippingAddress')} secondary={shippingAddress()} />
                <AddressRow primary={t('deliveryAddressOfTheAccommodation')} secondary={deliveryAddress()} />
                {tripType === TripType.RETURN && <AddressRow primary={t('pickupAddress')} secondary={pickupAddress()} />}
                {tripType === TripType.RETURN && <AddressRow primary={t('returnAddress')} secondary={returnAddress()} />}
              </List>
            </div>
            <div>
              <InputLabel>{t('otherInformationStep4')}</InputLabel>
              <Textarea
                fullWidth
                value={summary.otherInformation}
                onChange={(e) => dispatch(setOtherInformation(e.target.value))}
              />
            </div>
            <div>
              <Title marginBottom={2}>{t('luggageStep')}</Title>
              <Body>{t('tripType')}: {tripType === TripType.ONE_WAY ? t('oneWay') : t('twoWay')}</Body>
              <List dense disablePadding sx={{ maxWidth: "330px" }}>
                {confirmedLuggages.map(luggageItem)}
                {!noPlan && plan && (
                  <LuggageRow 
                    name={<>{t('cancellationEnsurance')}<br/><Subtitle fontSize="1.5rem" fontStyle="italic">{planName(plan)}</Subtitle></>} 
                    price={planPrice} />
                )}
                <Divider sx={{marginY: 1}} />
                <LuggageRow name={t('total')} price={total} />
                <LuggageRow name={t('tax')} price={vat} />
                <LuggageRow name={t('PriceWithoutTaxes')} price={subtotal} />
                <LuggageRow name={t('totalAmount')} price={total} />
              </List>
            </div>
            <div>
              <FormCheckbox
                value={termsAndConditions}
                isValid={error == null && termsAndConditions}
                helperText={error}
                onChange={e => onTermsAndConditionsChange(e.target.checked)}
                label={<Link href="/terms-and-conditions" target="_blank">{t('termsAndConditionsStep4')}</Link>}
              />
            </div>
            {user && user.role === 'admin' && (
              <div>
                <Title marginBottom={2}>{t('paymentMethod')}</Title>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <PaymentTypeSelect 
                      value={summary.paymentType}
                      onChange={(value) => dispatch(setPaymentType(value))}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {summary.paymentType === PaymentType.CARD && (
                      <TextField
                        fullWidth
                        placeholder={t('transactionId')}
                        value={summary.transactionId}
                        onChange={(e) => dispatch(setTransactionId(e.target.value))}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
          </Stack>
        </Stack>
      </Container>
      <StepperActions onFinish={handleFinish} onBack={onBack} />
    </Box>
  )
}

export default Summary;